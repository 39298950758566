<template>
  <div :class="riskClass">
    <svg class="mr-1 inline-block align-middle h-3 w-3 fill-current rounded-full border-2 border-white" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5" cy="5" r="5" />
    </svg>
    <span class="text-gray-800 inline-block align-middle font-medium">{{ badgeTitle }}</span>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core';
import { RiskState } from '@/models/dashboard';

export default {
  name: 'DashboardRiskCountBadge',
  props: {
    risk: {
      type: String
    },
    count: {
      type: Number
    }
  },
  setup(props) {
    const riskClass = computed(() => `inline-block align-middle text-xs ${riskColor(props.risk)}`);
    const badgeTitle = computed(() => `${props.count} ${props.risk}`);

    function riskColor(riskState) {
      switch (riskState) {
      case RiskState.Pending: return 'pending-text';
      case RiskState.Prepared: return 'prepared-text';
      case RiskState.Caution: return 'caution-text';
      default: 'pending-text';
      }
    }

    return {
      riskClass,
      badgeTitle
    };
  }
};
</script>

<style>
</style>
